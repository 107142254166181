import React, { Component } from 'react'
import Loading from '../../Loading'
import axios from 'axios';
import {Helmet} from 'react-helmet';

export class Formations extends Component {

    constructor(props){
        super();

        this.state = {
            paragraphes: null,
            citation: null,
        }
    }

    componentDidMount(){
        this.getPage()
    }

    getPage() {
        // this.getSpecifiedInfo("https://francegenest.herokuapp.com/formations", 'paragraphes');
        // this.getSpecifiedInfo("https://francegenest.herokuapp.com/formations/citation", 'citation');

        let paragraphes;
        let citation;

        const getParagraphes = axios.get('https://francegenest.herokuapp.com/formations')
        .then( (res) => {
            paragraphes = res.data;
        }).catch(err => console.log(err))

        const getCitation = axios.get('https://francegenest.herokuapp.com/formations/citation')
        .then( (res) => {
            citation = res.data;
        })

        Promise.all([getParagraphes, getCitation]).then( () => {
            this.setState({
                paragraphes,
                citation
            })
        })

    }

    getSpecifiedInfo(route, info) {

        axios.get(route)
        .then( (res) => {
           let infoData = res.data;
           this.setState({
                [info]: infoData
            })
        }).catch( err => console.log(err))
    }
    
    getParagraphes(){    

        if (this.state.paragraphes != null && this.state.paragraphes.length > 0 ) {

            return this.renderParagraphes()
        }else {
            return <Loading/>
        }
    }

    getCitation() {

        if (this.state.citation != null) {
            return <div className="formattedText" dangerouslySetInnerHTML={{ __html: this.state.citation.citation_formated }}></div>
        }else {
            return <Loading/>
        }
    }

    renderParagraphes(){
        
            let tabParTemp = "";

            return this.state.paragraphes.map( (paragraphe, index,  paragrapheArray) => {

                if (paragraphe.is_point === 1) {

                    if ( (paragrapheArray[index-1] != null && paragrapheArray[index-1].is_point === 1) || (paragrapheArray[index+1] != null && paragrapheArray[index+1].is_point === 1)) {

                        tabParTemp += paragraphe.text_formatted;
                        
                        if (paragrapheArray[index+1] == null || paragrapheArray[index+1].is_point === 0) {
                            
                            return <ul key={index} className="formattedText" dangerouslySetInnerHTML={{ __html: tabParTemp }}></ul>
                        } 

                        return "";
                    }
                   
                    return <ul key={index} className="formattedText" dangerouslySetInnerHTML={{ __html: paragraphe.text_formatted }}></ul>

                } else {

                    tabParTemp="";
                    return <div key={index} className="formattedText" dangerouslySetInnerHTML={{ __html: paragraphe.text_formatted }}></div>
                }
            })
    }

    render() {
        return (
            <div className="Formations">
                <Helmet>
                    <title>France Genest, Psychologue Boucherville - Services</title>
                    <meta name="description" content="Ceci dit, avec les années j'ai développé une approche intégrative ou j’utilise des outils de diverses approches afin de m'adapter aux besoins des gens que je rencontre. J'utilise, par exemple, régulièrement des outils tirés de l'approche cognitive-behaviorale et de l'approche centrée sur les solutions.

Ma dernière formation en a été une de deux ans qui m’a permis d’approfondir le travail avec les rêves. Puisque, selon Freud, l’interprétation des rêves est la voie royale de l’inconscient, j’apprécie beaucoup pouvoir travailler avec ces derniers.

Depuis la fin de mes études universitaires, j’ai pris soin de constamment mettre à jour ma formation avec diverses formules de formations continues (ateliers, conférences, supervision, etc.).

À l'origine, je suis d'approche humaniste. Après avoir complété ma maîtrise en psychologie à l’Université de Montréal, j’ai suivi une formation postuniversitaire de trois ans en Gestalt thérapie. J’ai fait cette formation au centre d’intervention gestaltiste (CIG)." />
                </Helmet>
                <h1 className="client-page-title">Formations et approches</h1>


                <section className="client-formations-container">


                    <section className="client-citation-container">
                        <div className="formattedImage">
                            <img src="./images/formations.jpg" alt="représentation qui accompagne la citation" width="390" height="273"/>
                        </div>
                        {this.getCitation()}
                    </section>

                    <div className="client-rendered-text">
                        {this.getParagraphes()}
                    </div>
                    
                </section>

            </div>
        )
    }
}

export default Formations