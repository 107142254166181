import React, { Component } from 'react'
import Body from '../utils/Body';
import Citation from '../utils/Citation';


export class Experience extends Component {

    constructor(){
        super();
        this.state = {
            selectedPage: null
        }
    }

    setSelectedPage(selectedPage){
        this.setState({
            selectedPage
        })
    }

    render() {
        const activeStyle = {
            textDecoration: 'underline'
        }
        return (
            <div className="admin-bienvenue">
                <div className="header-options">
                <p onClick={()=>{this.setSelectedPage("Points Clés")}} style={this.state.selectedPage === "texte" ? activeStyle : null}>Points Clés</p>
                <p onClick={()=>{this.setSelectedPage("citation")}} style={this.state.selectedPage === "texte" ? activeStyle : null}>Citation</p>
                </div>
                <div className="admin-intro" style={this.state.selectedPage === null ? {display: "block" } : {display: "none"}}>
                    <h2>Site administrateur</h2>
                    <p>Commencez à modifier le site principal en accédant à l'une des options du sous-menu</p>
                </div>

                <div style={this.state.selectedPage === 'Points Clés' ? {display: "block" } : {display: "none"}}><Body canBeDeleted={true} title="Points Clés" route="https://francegenest.herokuapp.com/experience/"/></div>
                <div style={this.state.selectedPage === 'citation' ? {display: "block" } : {display: "none"}}><Citation title="Citation" route="https://francegenest.herokuapp.com/experience/citation"/></div>
            </div>
        )
    }
}

export default Experience
