import React, { Component } from 'react'
import axios from 'axios';
import Loading from '../../Loading'

export class Citation extends Component {

    constructor(){
        super();
        this.state = {
            id_citation: null,
            text: "",
            auteur: "",
            citation_formated: null,
        }

        this.updateBanner = React.createRef();
        this.changeBanner = React.createRef();
    }

    componentDidMount(){
        this.getCitation();
    }

    getCitation(){
        axios.get(this.props.route)
        .then( (res) => {
            // console.log(res)
            this.setState({
                id_citation: res.data.id_citation,
                text: res.data.mots,
                auteur: res.data.auteur,
                citation_formated: res.data.citation_formated
            })
        })
    }

    setText(e){
        this.setState({
            text: e.target.value
        })
    }

    setAuteur(e){
        this.setState({
            auteur: e.target.value
        })   
    }

    sendInfoToDatabase(){
        console.log("SEND INFO")
        const { text, auteur, id_citation, citation_formated} = this.state;
        const citation = { text, auteur, id_citation, citation_formated };
        this.toggleChangeInProgressBanner();
        axios.post(this.props.route, citation)
        .then( async (res) => {
            this.getCitation();
            console.log("INFO RECEIVED")
            this.toggleChangeInProgressBanner();
            this.toggleUpdateBanner();
        })
    }

    toggleUpdateBanner() {
        const banner = this.updateBanner.current
        banner.classList.toggle("appear");

        setTimeout(() => {
            banner.classList.toggle("appear");
        }, 1500);
    }

    toggleChangeInProgressBanner() {

        console.log("called change in progress")
        const changeBanner = this.changeBanner.current
        changeBanner.classList.toggle("appear");
    }

    render() {
        return (
            <div className="citation">

                <section className="body-formatted">
                    <h1 className="body-section-title">Citation</h1>
                    {this.state.citation_formated==null ? <Loading/> : <div dangerouslySetInnerHTML={{ __html: this.state.citation_formated }}></div>}
                </section>

                <div className='champs-texte'>
                    <h3>Citation</h3>
                    <textarea value={this.state.text == null ? "" : this.state.text} onChange={(e)=>{this.setText(e)}}></textarea>
                    
                    <div className="citation-auteur-container"> 
                        <label>Auteur</label>
                        <input className="auteur" type="text" placeholder="Nom de l'auteur"  value={this.state.auteur == null ? "" : this.state.auteur} onChange={(e)=>{this.setAuteur(e)}}/>
                    </div>
                    
               
                    <div className="citation-bouton">
                        <p onClick={() => {this.sendInfoToDatabase()}} className='save'>Sauvegarder la citation</p>
                    </div>
                </div>

                <div className="update-banner" ref={this.updateBanner}>
                    <p>Changements réalisés</p>
                </div>
                <div className="change-banner" ref={this.changeBanner}>
                    <p>Modification en cours</p>
                    <div></div>
                </div>
            </div>
        )
    }
}

export default Citation
