import React, { Component } from 'react'
import Paragraphe from './Paragraphe';
import axios from 'axios';
import Loading from '../../Loading';


export class Body extends Component {

    constructor(props){
        super(props);
        this.state = {
                paragraphes: [],
                tabParTemp: ""
        }
        this.paragrapheRef = React.createRef();
        this.updateBanner = React.createRef();
        this.changeBanner = React.createRef();

    }


    componentDidMount(){
        this.getParagraphes();
    }

    getParagraphes(){    
           
       axios.get(this.props.route)
       .then( (res) => {
        let paragraphes = res.data;
        // console.log('FETCHED PARAGRAPHES', paragraphes)
        this.setParagraphes(paragraphes)
       })
    }

    renderParagraphes(){
        
        if(this.state.paragraphes.length > 0){
            // console.log("RENDER PARAGRAPHES", this.state.paragraphes)
            return this.state.paragraphes.map( (paragraphe, index) => {

                return <Paragraphe ref={this.paragrapheRef}
                key={index}
                route={this.props.route}
                champTitle={paragraphe.champTitle}
                title={this.props.title}
                canBeDeleted = {this.props.canBeDeleted}
                removeSelf={this.removeParagraphe.bind(this)}
                setFormattedText={this.setFormattedText.bind(this)} 
                index={index}
                text={paragraphe.text} 
                lien={paragraphe.lien} 
                format={paragraphe.format}
                priority={paragraphe.priority}
                is_point={paragraphe.is_point}
                id_paragraphe={paragraphe.id_paragraphe}
                setText={this.setText.bind(this)} 
                addLink={this.addLink.bind(this)}
                deleteLink={this.deleteLink.bind(this)}
                setLinkMots={this.setLinkMots.bind(this)}
                setLinkUrl={this.setLinkUrl.bind(this)}
                setFormat={this.setFormat.bind(this)}
                setPriority={this.setPriority.bind(this)}
                sendInfoToDatabase={this.sendInfoToDatabase.bind(this)}
                setParagraphes={this.setParagraphes.bind(this)}
                paragraphes={this.state.paragraphes}
                detectEnterKey={this.detectEnterKey.bind(this)}
                addPoint={this.addPoint.bind(this)}
                addParagraphe={this.addParagraphe.bind(this)}
               />
                
            })
        } else if (this.state.paragraphe === null) {
            return <Loading/>
        }
    }
    detectEnterKey (e, index) {
        if (e.key === "Enter") {
            console.log("NEXT")
            this.setText(e, index, "sendInfo");
        }
    }
    addParagraphe(id_paragraphe = null, isPoint=0, text = null, lien = null, formattedText = null, format = '0', priority = 0){
        console.log("par added")
        let paragraphe = {
            'formattedText': formattedText,
            'id_paragraphe': id_paragraphe,
            'text': text,
            'lien': lien,
            'is_point': isPoint,
            'format': format,
            'champTitle': 'Paragraphe',
            'priority': priority
        }
        let paragraphes = this.state.paragraphes;
        paragraphes.push(paragraphe);
        this.setState({
            paragraphes
        })
    }

    addPoint(id_paragraphe = null, isPoint=1, text = null, lien = null, formattedText = null, format = '0', priority = 0){
        let paragraphe = {
            'formattedText': formattedText,
            'id_paragraphe': id_paragraphe,
            'text': text,
            'lien': lien,
            'is_point': isPoint,
            'champTitle': 'Point',
            'format': format,
            'priority': priority
        }
        let paragraphes = this.state.paragraphes;
        paragraphes.push(paragraphe);
        this.setState({
            paragraphes
        })
    } 

    setParagraphes(paragraphes){

        let tabParagraphes = []

        paragraphes.map( (e) => {
            let paragraphe = {
                'formattedText': e.text_formatted,
                'id_paragraphe': e.id_paragraphe,
                'text': e.text,
                'lien': e.lien,
                'is_point': e.is_point,
                'format': e.format,
                'priority':e.priority
            }
            tabParagraphes.push(paragraphe);
            return true;
        })

        this.setState({
            paragraphes: tabParagraphes
        })
    }

    removeParagraphe(index){
        let paragraphes = this.state.paragraphes;
        //Si le paragraphe existe dans la DB
        if(paragraphes[index].id_paragraphe != null){
            let toBeDeleted = true;
            this.sendInfoToDatabase(index, toBeDeleted)
        }
        paragraphes.splice(index, 1);
        this.setState({
            paragraphes
        })
    }

    setFormattedText(formattedText, index){
        let paragraphes = this.state.paragraphes;
        paragraphes[index].formattedText = formattedText;
        this.setState({
            paragraphes
        })
    }

    getFormattedText(){
        
        if(this.state.paragraphes.length > 0){

            let tabParTemp = "";

            return this.state.paragraphes.map( (paragraphe, index,  paragrapheArray) => {

                // si le texte évalué est un point
                if (paragraphe.is_point === 1) {

                        // SI l'élément passé n'est pas null
                        // ET SI l'élément passé est aussi un point
                        // OU
                        // SI l'élément prochain n'est pas null
                        // ET SI l'élément prochain est aussi un point
                        if ( (paragrapheArray[index-1] != null && paragrapheArray[index-1].is_point === 1) || (paragrapheArray[index+1] != null && paragrapheArray[index+1].is_point === 1)) {

                            // BUT: ne pas générer de balises ul ou div, juste le texte formaté
                            // on y arrive on ne générant rien du tout et en sauvegardant l'element present dans une chaine de caractere
                            tabParTemp += paragraphe.formattedText; // on ajoute cet element au tableau de caracteres
                            
                            //SI l'element prochain est null
                            //OU
                            //SI l'element prochain n'est pas un point
                            if (paragrapheArray[index+1] == null || paragrapheArray[index+1].is_point === 0) {
                                
                                //generer la liste ul-li
                                return <ul key={index} className="formattedText" dangerouslySetInnerHTML={{ __html: tabParTemp }}></ul>

                            } 

                            return null;
                        }
                   
                    return <ul key={index} className="formattedText" dangerouslySetInnerHTML={{ __html: paragraphe.formattedText }}></ul>

                } else {
                    //si le texte évalué n'est pas un point
                    tabParTemp="";//vider le tableau
                    return <div key={index} className="formattedText" dangerouslySetInnerHTML={{ __html: paragraphe.formattedText }}></div>
                }
            })
        } else if (this.state.paragraphe === null) {
            return <Loading/>
        }else {
            return <div>Vide</div>
        }
    }

    setText(e, index, send){
        let paragraphes = this.state.paragraphes;
        paragraphes[index].text = e.target.value;
        this.setParagrapheState(paragraphes);
        if (send === "sendInfo") {
            this.sendInfoToDatabase(index)
        }
    }

    setLinkMots (e, index) {
        const { paragraphes } = this.state;
        let paragraphesCopy = this.state.paragraphes;
        let lien = {
            "id_lien": paragraphes[index].lien.id_lien,
            "mots": e.target.value,
            "url": paragraphes[index].lien.url
        }
        paragraphesCopy[index].lien = lien;
        this.setParagrapheState(paragraphesCopy);
        
    }

    setLinkUrl(e, index){
        const { paragraphes } = this.state;
        let paragraphesCopy = this.state.paragraphes;
        let lien = {
            "id_lien": paragraphes[index].lien.id_lien,
            "mots": paragraphes[index].lien.mots,
            "url": e.target.value
        }
        paragraphesCopy[index].lien = lien;
        this.setParagrapheState(paragraphesCopy);
    }

    setFormat(e, index) {
        let paragraphesCopy = this.state.paragraphes;
        let format = e.target.value;
        paragraphesCopy[index].format = format;
        this.setParagrapheState(paragraphesCopy);
    }

    setPriority(e, index) {
        let paragraphesCopy = this.state.paragraphes;
        let priority = e.currentTarget.value;
        paragraphesCopy[index].priority = priority;
        this.setParagrapheState(paragraphesCopy);
    }
    
    addLink(index){
        let paragraphesCopy = this.state.paragraphes;
        let lien = {
            "id_lien": "",
            "mots": "",
            "url": ""
        }

        paragraphesCopy[index].lien = lien;
        this.setParagrapheState(paragraphesCopy);
    }

    deleteLink(index){
        let paragraphesCopy = this.state.paragraphes;
        paragraphesCopy[index].lien = null;
        this.setParagrapheState(paragraphesCopy);
    }

    sendInfoToDatabase(index, toBeDeleted = false){
        console.log('SEND INFO')
        let paragraphe = this.state.paragraphes[index];
        //Si le paragraphe doit etre enlever
        if(toBeDeleted){
            paragraphe.text = "";
            paragraphe.lien = null
        }
        this.toggleChangeInProgressBanner()
        //Si le paragraphe contient du texte
        axios.post(this.props.route, paragraphe)
            .then( async (res) => {
                this.getParagraphes();
                //toggle classname
                console.log('INFO RECEIVED')
                this.toggleChangeInProgressBanner()
                this.toggleUpdateBanner();
            })
    }

    setParagrapheState(paragraphes){
        this.setState({paragraphes});
    }

    toggleChangeInProgressBanner() {
        const changeBanner = this.changeBanner.current
        changeBanner.classList.toggle("appear");
    }

    toggleUpdateBanner() {
        const banner = this.updateBanner.current
        banner.classList.toggle("appear");

        setTimeout(() => {
            banner.classList.toggle("appear");
        }, 1500);
    }

    render() {
        return (
            <div className="body">

                <section className="body-formatted">
                    <h1 className="body-section-title">{this.props.title}</h1>
                    {this.getFormattedText()}
                    {/* {
                        (this.props.title === "Adresse") ? 
                        <div dangerouslySetInnerHTML={{ __html: this.state.img_formatted }}></div>
                        :
                        null
                    } */}
                </section>

                    {this.renderParagraphes()}
                
                    <section className="btn-container">
                        
                        {
                            // (this.props.title === "Adresse") ? null :
                            <div className="add">
                            <img alt="bouton ajouter" onClick={() => this.addParagraphe()} src='./images/add_circle-black-18dp.svg'/>
                            {
                                (this.props.title === "Téléphone") ? <p>Ajouter un numéro de téléphone</p> :<p>Ajouter un paragraphe</p>
                            }
                            </div>
                        }
                        

                        {
                            (this.props.title === "Téléphone" || this.props.title === "Adresse") ? null :
                            <div className="add">
                                <img  alt="bouton ajouter" onClick={() => this.addPoint()} src='./images/add_circle-black-18dp.svg'/>
                                <p>Ajouter un point</p>
                            </div>
                        }
                        
                    </section>

                <div className="update-banner" ref={this.updateBanner}>
                    <p>Changements effectués</p>
                </div>
                <div className="change-banner" ref={this.changeBanner}>
                    <p>Modification en cours</p>
                    <div></div>
                </div>
            </div>
        )
    }
}

export default Body
