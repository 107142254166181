import React, { Component } from 'react'

export class ErrorPage extends Component {

    constructor(props){
        super();
    }

    render() {
        return (
            <div className="ErrorPage">
              <h1>Page introuvable</h1>
            </div>
        )
    }
}

export default ErrorPage