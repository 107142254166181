import React, { Component } from 'react'
import Loading from '../../Loading'
import axios from 'axios';
import {Helmet} from 'react-helmet';

export class Experience extends Component {

    constructor(props){
        super();

        this.state = {
            paragraphes: null,
            citation: null,
        }
    }

    componentDidMount(){
        this.getPage()
    }
    getPage() {
        // this.getSpecifiedInfo("https://francegenest.herokuapp.com/experience", 'paragraphes');
        // this.getSpecifiedInfo("https://francegenest.herokuapp.com/experience/citation", 'citation');


        let paragraphes;
        let citation;

        const getParagraphes = axios.get('https://francegenest.herokuapp.com/experience')
        .then( (res) => {
            paragraphes = res.data;
        }).catch(err => console.log(err))

        const getCitation = axios.get('https://francegenest.herokuapp.com/experience/citation')
        .then( (res) => {
            citation = res.data;
        })

        Promise.all([getParagraphes, getCitation]).then( () => {
            this.setState({
                paragraphes,
                citation
            })
        })

    }
    getSpecifiedInfo(route, info) {

        axios.get(route)
        .then( (res) => {
           let infoData = res.data;
           this.setState({
                [info]: infoData
            })
        }).catch( err => console.log(err))
    }
    
    getParagraphes(){    

        if (this.state.paragraphes != null && this.state.paragraphes.length > 0 ) {

            return this.renderParagraphes()
        }else {
            return <Loading/>
        }
       
    }
    getCitation() {

        if (this.state.citation != null) {
            return <div className="formattedText" dangerouslySetInnerHTML={{ __html: this.state.citation.citation_formated }}></div>
        }else {
            return <Loading/>
        }
    }

    renderParagraphes(){
        
            let tabParTemp = "";

            return this.state.paragraphes.map( (paragraphe, index,  paragrapheArray) => {

                if (paragraphe.is_point === 1) {

                    if ( (paragrapheArray[index-1] != null && paragrapheArray[index-1].is_point === 1) || (paragrapheArray[index+1] != null && paragrapheArray[index+1].is_point === 1)) {

                        tabParTemp += paragraphe.text_formatted;
                        
                        if (paragrapheArray[index+1] == null || paragrapheArray[index+1].is_point === 0) {
                            
                            return <ul key={index} className="formattedText" dangerouslySetInnerHTML={{ __html: tabParTemp }}></ul>
                        } 

                        return "";
                    }
                   
                    return <ul key={index} className="formattedText" dangerouslySetInnerHTML={{ __html: paragraphe.text_formatted }}></ul>

                } else {

                    tabParTemp="";
                    return <div key={index} className="formattedText" dangerouslySetInnerHTML={{ __html: paragraphe.text_formatted }}></div>
                }
            })
    }

    render() {
        return (
            <div className="Experience">
                 <Helmet>
                    <title>France Genest, Psychologue Boucherville - Mon Expérience</title>
                    <meta name="description" content="J’ai plus de 20 ans d’expérience à titre de psychologue.
J’ai travaillé dans différents milieux auprès de clientèles qui présen- taient des problématiques variées.
J’ai oeuvré dans le réseau de la santé (en C.L.S.C. et en centre de crise), en milieu communautaire, pour des programmes d’aide aux employés (PAE) ainsi qu’en bureau privé.
J’ai de l’expérience dans des suivis de court, moyen et long terme.
J’ai travaillé auprès d’enfants, d’adolescents, d’adultes de tout âge et finalement, autant auprès de femmes que d’hommes." />
                </Helmet>

                <h1 className="client-page-title">Mon Expérience</h1>

                

                <section className="client-experience-container">

                    <section className="client-citation-container">
                        <div className="formattedImage">
                            <img src="./images/experience.jpg" alt="représentation qui accompagne la citation" width="390" height="247"/>
                        </div>
                        {this.getCitation()}
                    </section>
                    
                    <div className="client-rendered-text">
                        {this.getParagraphes()}
                    </div>
                    
                </section>

            </div>
        )
    }
}

export default Experience