import React, { Component } from 'react'
import Bienvenue from './page/Bienvenue';
import Formations from'./page/Formations';
import Coordonnees from './page/Coordonnees.js';
import Experience from './page/Experience';
import Services from './page/Services';



export class Client extends Component {

    constructor(){
        super();

        this.state = {
            selectedPage: "Bienvenue",
        }

        this.headerRef = React.createRef();
    }

    getHeader(){
        
        return  <div ref={this.headerRef} className="header display-none">
            <p className={this.props.location.pathname === "/accueil" ? 'active-menu-option' : null} onClick={()=>{this.setState({selectedPage:"Bienvenue"});this.props.history.push('/accueil')}} >Bienvenue</p>
            <p className={this.props.location.pathname === "/formations" ? 'active-menu-option' : null}  onClick={()=>{this.setState({selectedPage:"Formations"});this.props.history.push('/formations') }}>Formations et approches</p>
            <p className={this.props.location.pathname === "/experience" ? 'active-menu-option' : null}  onClick={()=>{this.setState({selectedPage:"Experience"});this.props.history.push('/experience') }}>Mon expérience</p>
            <p className={this.props.location.pathname === "/services" ? 'active-menu-option' : null}  onClick={()=>{this.setState({selectedPage:"Services"});this.props.history.push('/services') }}>Services</p>
            <p className={this.props.location.pathname === "/coordonnees" ? 'active-menu-option' : null}  onClick={()=>{this.setState({selectedPage:"Coordonnees"});this.props.history.push('/coordonnees')}}>Mes coordonnées</p>
        </div>
    }

    handleMenu = e => {

        const menu = this.headerRef.current;
        menu.classList.toggle("display-none")
    }

    render() {
        return (
            <div className="client">
                <div className="menu-icon" onClick={(e)=>{this.handleMenu(e)}}><img alt="menu-icon" width="25px" src="./images/menu-icon.png"/><span>{this.state.selectedPage}</span></div>
               {this.getHeader()}

                <div style={(this.state.selectedPage === "Bienvenue" && (this.props.location.pathname === "/accueil" || this.props.location.pathname === "/")) ? {display: "flex"} : {display: "none"}}>
                    <Bienvenue/>
                </div>

                <div style={ (this.state.selectedPage === "Coordonnees" || this.props.location.pathname === "/coordonnees") ? {display: "flex"} : {display: "none"}}>
                    <Coordonnees/>
                </div>

                <div style={ (this.state.selectedPage === "Experience" || this.props.location.pathname === "/experience") ? {display: "flex"} : {display: "none"}}>
                    <Experience/>
                </div>

                <div style={ (this.state.selectedPage === "Formations" || this.props.location.pathname === "/formations") ? {display: "flex"} : {display: "none"}}>
                    <Formations/>
                </div>
                <div style={ (this.state.selectedPage === "Services" || this.props.location.pathname === "/services") ? {display: "flex"} : {display: "none"}}>
                    <Services/>
                </div>
                
            </div>
        )
    }
}

export default Client