import React from 'react';
import Admin from './components/admin/admin';
import Client from './components/client/client';
import ErrorPage from './components/ErrorPage';
import { Route, Switch, withRouter} from 'react-router-dom';
import './App.css';
import './css/main.css';
import Footer from './components/Footer'

class App extends React.Component {

  render(){
    return (
      <div className="App">
        
        <section className="app-header">
            <img src='./images/logo-2.png' alt='logo'/>
            <p>France Genest</p>
            <p>Psychologue</p>
            <p style={{fontSize:"14px", margin: "0"}}>à Boucherville</p>
        </section>
        
        <section className="main-section">
        <Switch location={this.props.location}>
              <Route exact path={["/", "/accueil", "/formations", "/experience", "/services", "/coordonnees"]} component={Client}></Route>
              <Route exact path="/admin" component={Admin}></Route>
              <Route component={ErrorPage} />
        </Switch>
        </section>

          <Footer history={this.props.history} location={this.props.location}/>
      </div>
    );
  }
}

export default withRouter(App);
