import React, { Component } from 'react'
import Champs from './ChampsTexte';
// import axios from 'axios';



export class Paragraphe extends Component {
    
    constructor(props){
        super(props);
        this.apercuRef = React.createRef();
        this.champRef = React.createRef();

        this.state = {
            champOuvert: false,
    }


    }

    componentDidMount() {

        if (this.props.text === null || this.props.text === "") {
            this.openSection();
        }
    }

    openSection (e) {
        const apercu = this.apercuRef.current;
        const champ = this.champRef.current;

        if (e!== undefined && e.currentTarget.classList.contains("save") && champ.classList.contains("save")===false) {
            
            return false;
        }

        if (this.state.champOuvert === false) {
            this.setState({
                champOuvert:true
            })
        } else {
            this.setState({
                champOuvert:false
            })
        }

        apercu.classList.toggle("section-display");
        champ.classList.toggle("section-display");

    }

    enterKeyPressed (e) {
        if (e.key === "Enter") {
            this.openSection();
        }
    }

    render() {
        return (
            <div className="paragraphe">

               

                <p onClick={(e)=>{this.openSection(e)}} className="see-more-btn"> <img alt="right-arrow" src="./images/right-arrow.png"/> </p>
                 
                {
                    (this.props.title === "Adresse" || this.state.champOuvert !== false) ?
                    null :
                    <div className="champ-priority apercu">
                        <input type="number" min="0" max="100" onChange={(e) => {this.props.setPriority(e, this.props.index)}} value={this.props.priority}/>
                    </div>
                }
                 
                 <p ref={this.apercuRef} className="champ-apercu">{this.props.text}</p>
                   
                <section className="Champs section-display" ref={this.champRef}>
                    <Champs 
                    title={this.props.title}
                    champTitle={this.props.champTitle}
                    text={this.props.text} 
                    lien={this.props.lien} 
                    index={this.props.index}
                    format={this.props.format}
                    priority={this.props.priority}
                    isPoint={this.props.is_point}
                    setText={this.props.setText.bind(this)} 
                    addLink={this.props.addLink.bind(this)}
                    deleteLink={this.props.deleteLink.bind(this)}
                    setLinkMots={this.props.setLinkMots.bind(this)}
                    setLinkUrl={this.props.setLinkUrl.bind(this)}
                    setFormat={this.props.setFormat.bind(this)}
                    setPriority={this.props.setPriority.bind(this)}
                    setParagraphes={this.props.setParagraphes.bind(this)}
                    paragraphes={this.props.paragraphes}
                    detectEnterKey={this.props.detectEnterKey.bind(this)}
                    enterKeyPressed={this.enterKeyPressed.bind(this)}
                    />
                </section>
                <div className="paragraphe-bouton">
                <p onClick={(e) => {this.props.sendInfoToDatabase(this.props.index); this.openSection(e)  }} className='save'>Sauvegarder</p>
                { this.props.canBeDeleted ? ((this.props.title === "Téléphone" && this.props.paragraphes.length === 1) ? null : <p onClick={() => {this.props.removeSelf(this.props.index)}} className="remove">Enlever</p>)  : null}
                </div>
         </div>
        )
    }
}

export default Paragraphe
