import React, { Component } from 'react'
import Loading from '../../Loading'
import axios from 'axios';
import {Helmet} from 'react-helmet';

export class Services extends Component {

    constructor(props){
        super();

        this.state = {
            consultations: null,
            honoraires: null,
            citation: null,
        }
    }

    componentDidMount(){
        this.getPage()
    }
    getPage() {
        // this.getSpecifiedInfo("https://francegenest.herokuapp.com/services/consultations", 'consultations');
        // this.getSpecifiedInfo("https://francegenest.herokuapp.com/services/honoraires", 'honoraires');
        // this.getSpecifiedInfo("https://francegenest.herokuapp.com/services/citation", 'citation');

        let consultations;
        let honoraires;
        let citation;

        const getConsultations = axios.get('https://francegenest.herokuapp.com/services/consultations')
        .then( (res) => {
            consultations = res.data;
        }).catch(err => console.log(err))

        const getHonoraires = axios.get('https://francegenest.herokuapp.com/services/honoraires')
        .then( (res) => {
            honoraires = res.data;
        })

        const getCitation = axios.get('https://francegenest.herokuapp.com/services/citation')
        .then( (res) => {
            citation = res.data;
        })


        Promise.all([getConsultations, getHonoraires, getCitation]).then( () => {
            this.setState({
                consultations,
                honoraires,
                citation
            })
        })

    }
    getSpecifiedInfo(route, info) {

        axios.get(route)
        .then( (res) => {
           let infoData = res.data;
           this.setState({
                [info]: infoData
            })
        }).catch( err => console.log(err))
    }
    
    getConsultations(){    

        if (this.state.consultations != null && this.state.consultations.length > 0 ) {

            return this.renderParagraphes(this.state.consultations)
        }else {
            return <Loading/>
        }
    }
    getHonoraires(){    

        if (this.state.honoraires != null && this.state.honoraires.length > 0 ) {

            return this.renderParagraphes(this.state.honoraires)
        }else {
            return <Loading/>
        }
    }
    getCitation() {

        if (this.state.citation != null) {
            return <div className="formattedText" dangerouslySetInnerHTML={{ __html: this.state.citation.citation_formated }}></div>
        }else {
            return <Loading/>
        }
    }

    renderParagraphes(tabPar){
        
            let paragraphes = tabPar;
            let tabParTemp = "";

            return paragraphes.map( (paragraphe, index,  paragrapheArray) => {

                if (paragraphe.is_point === 1) {

                    if ( (paragrapheArray[index-1] != null && paragrapheArray[index-1].is_point === 1) || (paragrapheArray[index+1] != null && paragrapheArray[index+1].is_point === 1)) {

                        tabParTemp += paragraphe.text_formatted;
                        
                        if (paragrapheArray[index+1] == null || paragrapheArray[index+1].is_point === 0) {
                            
                            return <ul key={index} className="formattedText" dangerouslySetInnerHTML={{ __html: tabParTemp }}></ul>
                        } 

                        return "";
                    }
                   
                    return <ul key={index} className="formattedText" dangerouslySetInnerHTML={{ __html: paragraphe.text_formatted }}></ul>

                } else {

                    tabParTemp="";
                    return <div key={index} className="formattedText" dangerouslySetInnerHTML={{ __html: paragraphe.text_formatted }}></div>
                }
            })
    }

    render() {
        return (
            <div className="Services">
                 <Helmet>
                    <title>France Genest, Psychologue Boucherville - Services</title>
                    <meta name="description" content="Difficultés familliales
                    Difficultés conjugales
                    Difficultés au travail
                    Difficultés de communication
                    Difficultés d'affirmation de soi
                    Anxiété - Stress
                    Timidité
                    Estime de soi
                    Épuisement professionnel
                    Dépression
                    Séparation - Divorce
                    Adaptation à diverses étapes de vie (ex: naissance d'un enfant, retraite, etc.).
                    Périnatalité
                    Prise de décision complexe" />
                </Helmet>
                <h1 className="client-page-title">Services</h1>
                

                <section className="client-services-container">

                    <div className="citation-motifs">
                        <section className="client-citation-container">
                            <div className="formattedImage">
                                <img src="./images/services.jpg" alt="représentation qui accompagne la citation" width="504" height="366"/>
                            </div>
                            {this.getCitation()}
                        </section>

                        <div className="client-rendered-text">
                            <h3 className="subtitle">Motifs de consultations</h3>
                            {this.getConsultations()}
                        </div>
                    </div>

                    <div className="client-rendered-text">
                        <h3 className="subtitle">Honoraires</h3>
                        {this.getHonoraires()}
                    </div>
                    
                </section>
               
            </div>
        )
    }
}

export default Services