import React, { Component } from 'react'
import Loading from '../../Loading'
import axios from 'axios';
import {Helmet} from 'react-helmet';

export class Coordonnees extends Component {

    constructor(){
        super();

        this.state = {
            paragraphes: null,
            adresse: null,
            citation: null,
            telephones: null,
        }
    }

    componentDidMount(){
        this.getPage()
    }

    getPage() {
        let adresse;
        let citation;
        let telephones;

        const getAdresse = axios.get('https://francegenest.herokuapp.com/coordonnees/adresse')
        .then( (res) => {
            adresse = res.data;
        }).catch(err => console.log(err))

        const getCitation = axios.get('https://francegenest.herokuapp.com/coordonnees/citation')
        .then( (res) => {
            citation = res.data;
        })

        const getTelephones = axios.get('https://francegenest.herokuapp.com/coordonnees/telephone')
        .then( (res) => {
            telephones = res.data;
        })

        Promise.all([getAdresse, getCitation, getTelephones]).then( () => {
            this.setState({
                adresse,
                citation,
                telephones
            })
        })

        // this.getSpecifiedInfo("https://francegenest.herokuapp.com/coordonnees/adresse", 'adresse');
        // this.getSpecifiedInfo("https://francegenest.herokuapp.com/coordonnees/telephone", 'telephones');
        // this.getSpecifiedInfo("https://francegenest.herokuapp.com/coordonnees/citation", 'citation');
    }
    
    getSpecifiedInfo(route, info) {

        axios.get(route)
        .then( (res) => {
           let infoData = res.data;
           this.setState({
                [info]: infoData
            }).catch( err => console.log(err))
            
        })
    }

    getAdresse() {
        
        // if (this.state.adresse == null) {console.log("Adresse: "+this.state.adresse)}
        if (this.state.adresse !== null) {
            return <div className="formattedText" dangerouslySetInnerHTML={{ __html: this.state.adresse[0].text_formatted }}></div>
        }
        else {
            return <Loading/>
        }
    }
    getTelephone() {
        if (this.state.telephones != null && this.state.telephones.length > 0) {

            return this.state.telephones.map((telephone, index) => {

                return <div key={index} className="formattedText" dangerouslySetInnerHTML={{ __html: telephone.text_formatted }}></div>
            })

        }else {
            return <Loading/>
        }
    }
   
    getCitation() {

        if (this.state.citation != null) {
            return <div className="formattedText" dangerouslySetInnerHTML={{ __html: this.state.citation.citation_formated }}></div>
        }else {
            return <Loading/>
        }
    }

    render() {
        return (
            <div className="Coordonnees">
                <Helmet>
                    <title>France Genest, Psychologue Boucherville - Mes Coordonnées</title>
                    <meta name="description" content="1398 De Rouen, Boucherville, Qc J4B 7Y3, (514) 804-6351" />
                </Helmet>
                <h1 className="client-page-title">Mes coordonnées</h1>

                <section className="client-coordonnees-container">

                    <div className="client-rendered-text">
                        <h2 className="subtitle">Téléphone</h2>
                        {this.getTelephone()}
                    </div>

                    <div className="client-rendered-text">
                        <h2 className="subtitle">Adresse</h2>
                        {this.getAdresse()}
                    </div>
                    
                </section>

                <section className="client-citation-container">
                    <div className="formattedImage">
                            <img src="./images/coordonnees.jpg" alt="représentation qui accompagne la citation" width="449" height="338"/>
                        </div>
                    {this.getCitation()}
                </section>
                
                <section className="coordonnees-carte">
                    <iframe title="google map address" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2792.523667185468!2d-73.43127864814004!3d45.58001667899998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc902dd01e42dd5%3A0x4c9b41c0ab25dfd1!2s1398%20Rue%20de%20Rouen%2C%20Boucherville%2C%20QC%20J4B%207Y3!5e0!3m2!1sen!2sca!4v1595965204739!5m2!1sen!2sca"   width="100%" height="350" frameBorder="0" style={{border:"0"}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                </section>
                
            </div>
        )
    }
}

export default Coordonnees