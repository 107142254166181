import React, { Component } from 'react'

export class Loading extends Component {

    render() {
        return (
            <div className="Loading">
               <div className="spinner"></div>
            </div>
        )
    }
}

export default Loading