import React, { Component } from 'react'
import Loading from '../../Loading'
import axios from 'axios';
import {Helmet} from 'react-helmet';

export class Bienvenue extends Component {

    constructor(props){
        super();

        this.state = {
            paragraphes: null,
            citation: null,
        }

    }

    componentDidMount(){
        this.getPage()
    }
    
    getPage() {

        let paragraphes;
        let citation;

        const getParagraphes = axios.get('https://francegenest.herokuapp.com/bienvenue')
        .then( (res) => {
            paragraphes = res.data;
        }).catch(err => console.log(err))

        const getCitation = axios.get('https://francegenest.herokuapp.com/bienvenue/citation')
        .then( (res) => {
            citation = res.data;
        })

        Promise.all([getParagraphes, getCitation]).then( () => {
            this.setState({
                paragraphes,
                citation
            })
        })

        // this.getSpecifiedInfo("https://francegenest.herokuapp.com/bienvenue", 'paragraphes');
        // this.getSpecifiedInfo("https://francegenest.herokuapp.com/bienvenue/citation", 'citation');
    }

    getSpecifiedInfo(route, info) {
        console.log("GET SPECIFIED INFO")
        axios.get(route)
        .then( (res) => {
            console.log("FETCHED BIENVENUE", res)
           let infoData = res.data;
           this.setState({
                [info]: infoData
            })
        }).catch( err => console.log(err))
    }

    getParagraphes(){    

        if (this.state.paragraphes != null && this.state.paragraphes.length > 0 ) {

            return this.renderParagraphes()
        }else {
            return <Loading/>
        }
       
    }

    getCitation() {

        if (this.state.citation != null) {
            return <div className="formattedText" dangerouslySetInnerHTML={{ __html: this.state.citation.citation_formated }}></div>
        }else {
            return <Loading/>
        }
    }

    renderParagraphes(){
        
            let tabParTemp = "";

            return this.state.paragraphes.map( (paragraphe, index,  paragrapheArray) => {

                if (paragraphe.is_point === 1) {

                    if ( (paragrapheArray[index-1] != null && paragrapheArray[index-1].is_point === 1) || (paragrapheArray[index+1] != null && paragrapheArray[index+1].is_point === 1)) {

                        tabParTemp += paragraphe.text_formatted;
                        
                        if (paragrapheArray[index+1] == null || paragrapheArray[index+1].is_point === 0) {
                            
                            return <ul key={index} className="formattedText" dangerouslySetInnerHTML={{ __html: tabParTemp }}></ul>
                        } 

                        return "";
                    }
                   
                    return <ul key={index} className="formattedText" dangerouslySetInnerHTML={{ __html: paragraphe.text_formatted }}></ul>

                } else {

                    tabParTemp="";
                    return <div key={index} className="formattedText" dangerouslySetInnerHTML={{ __html: paragraphe.text_formatted }}></div>
                }
            })
    }


    render() {
        return (
            <div className="Bienvenue">
                <Helmet>
                    <title>France Genest, Psychologue Boucherville - Bienvenue</title>
                    <meta name="description" content="Depuis 1999, je suis membre de l’Ordre des psychologues du Québec." />
                </Helmet>
                <section className="client-citation-container">
                        <div className="formattedImage">
                            <img src="./images/accueil.jpg" alt="représentation qui accompagne la citation" width="500" height="386"/>
                        </div>
                    {this.getCitation()}
                </section>

                <section className="client-bienvenue-container">
                    <h1 className="client-page-title">Bienvenue</h1>
                    
                    <div className="client-rendered-text">
                        {this.getParagraphes()}
                    </div>
                </section>
                
            </div>
        )
    }
}

export default Bienvenue