import React, { Component } from 'react'

export class ChampsTexte extends Component {
    

    getLien(lien){
        const {setLinkMots, setLinkUrl, deleteLink, addLink, index} = this.props;
        if(lien != null){
                return<div className="lien-el">
                    <div className="delete">
                            <img alt="bouton supprimer" onClick={() => {deleteLink(index)}} src="./images/clear-black-18dp.svg"/>
                        </div>
                        <div className="name">
                            <p>Ajouter le lien sur les mots:</p>
                            <textarea value={lien.mots} onChange={(e) => {setLinkMots(e, index)}}></textarea>
                        </div>
                        <div className="url">
                            <p>URL</p>
                            <textarea value={lien.url} onChange={(e) => {setLinkUrl(e, index)}}></textarea>
                        </div>
                        
                    </div>     
        }
        else if (this.props.title === "Téléphone") {

            return null;
        }
        else{
            return <div className="add">
            <img alt="bouton ajouter" onClick={() => {addLink(index)}} src='./images/add_circle-black-18dp.svg'/>
            <p>Ajouter un lien</p>
            </div>
        }
    }

    formatPhoneNumber = (e, index, setText) => {

        var cleaned = ('' + e.target.value).replace(/\D/g, '')
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '')
            e.target.value = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
            
            setText(e, index);

            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        setText(e, index);
        return null
        
    }

    render() {
        const {title, text, lien, setText, index, setFormat, setPriority, priority, format, detectEnterKey, enterKeyPressed} = this.props;
        return (
            <div className='champs-texte'>
                <h2>{this.props.champTitle}</h2>
                
                {
                    title === "Téléphone" ? 
                    <section className="champ-tel">
                        <label htmlFor={"tel-"+index}>Numéro de téléphone </label>
                        <input value={text == null ? '' : text} onChange={(e)=>{this.formatPhoneNumber(e, index, setText)}} type="tel" id={"tel-"+index} name="telephone" pattern="[0-9]{3} [0-9]{3} [0-9]{4}" maxLength="15"  title="Ten digits code" placeholder="e.g.:888 888 8888, +8 888 888 8888" required/> 
                    </section>
                    :
                    <textarea value={text == null ? '' : text} onKeyUp={(e)=>{detectEnterKey(e, index);enterKeyPressed(e, this.props.isPoint)}} onChange={(e)=>{setText(e, index)}}></textarea>
                }

                <section className="champs-fp">
                {
                    this.props.isPoint === 1 ? "" :

                    <div className="champ-format">
                        <label>Format du parapgraphe </label>
                        <select onChange={(e) => {setFormat(e, index)}} value={format==null ? '0' : format} >
                            <option value="0">Normal</option>
                            <option value="1">Titre</option>
                            <option value="2">Sous-titre</option>
                            <option value="3">Petit</option>
                        </select>
                    </div>
                }

                {
                    this.props.title === "Adresse" ?
                    null :
                    <div className="champ-priority">
                        <label>Priorité du paragraphe </label>
                        <input type="number" min="0" max="100" onChange={(e) => {setPriority(e, index)}} value={priority}/>
                    </div>
                }

                </section>
                {
                    this.props.title === "Téléphone" ?
                    null :
                    <div className="lien">
                        {this.getLien(lien)}    
                    </div>
                }
                
            </div>
        )
    }
}

export default ChampsTexte
