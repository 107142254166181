import React, { Component } from 'react'
import axios from 'axios';
import Bienvenue from './page/Bienvenue';
import Formations from'./page/Formations';
import Coordonnees from './page/Coordonnees';
import Experience from './page/Experience';
import Services from './page/Services';

export class Admin extends Component {

    constructor(props){
        super();
        this.username = React.createRef();
        this.password = React.createRef();
        this.state = {
            isAuthentificated: false,
            selectedPage: "Bienvenue",
            err: null
        }
        this.headerRef = React.createRef();
    }

    componentDidMount(){
        const user = window.localStorage.getItem("user-france-genest");
        if(user === "true"){
            this.toggleAuthentification();
        }
      
    }

    logOut(){
        window.localStorage.setItem("user-france-genest", "false");
        this.toggleAuthentification();
    }

    toggleAuthentification(){
        this.setState(prevState => ({
            isAuthentificated: !prevState.isAuthentificated
        }))
    }

    handleSubmit(e){
        e.preventDefault();
        const username = this.username.current.value;
        const password = this.password.current.value;
        axios.get(`https://francegenest.herokuapp.com/login/${username}/${password}`)
        .then( (res) => {
            console.log(res.data)
            if(res.data === true){ 
                 window.localStorage.setItem("user-france-genest", "true")
                this.toggleAuthentification();
            }
            // else {
            //     console.log("mdp false")
            //     // return <p className="login-msg">Mot de passe ou utilisateur incorrect</p>
            // }
        })
        .catch(err => {console.log(err); this.setState({err})} ) 
    }

    voirMdp () {

        const password = this.password.current;

        if (password.type === "password") {
            password.type = "text"
        } else {
            password.type = "password"
        }
    }

    getAdmin(){
        const { isAuthentificated } = this.state;
        if(isAuthentificated === true){
            return  <>
            <div style={this.state.selectedPage === "Bienvenue" ? {display: "flex"} : {display: "none"}}>
                <Bienvenue />
            </div>
            <div style={this.state.selectedPage === "Formations" ? {display: "flex"} : {display: "none"}}>
                <Formations />
            </div>
            <div style={this.state.selectedPage === "Coordonnées" ? {display: "flex"} : {display: "none"}}>
                <Coordonnees />
            </div>
            <div style={this.state.selectedPage === "Expérience" ? {display: "flex"} : {display: "none"}}>
                <Experience />
            </div>
            <div style={this.state.selectedPage === "Services" ? {display: "flex"} : {display: "none"}}>
                <Services />
            </div>
            </>
        }
        else{
            return <form className="login-form" onSubmit={this.handleSubmit.bind(this)}>
                {
                    this.state.err == null ? null : <p className="login-msg">Mot de passe ou nom d'utilisateur incorrect</p>
                }
                <div className="login-field">
                    <p>Nom d'utilisateur</p>
                    <input type="text" ref={this.username}/>
                </div>

                <div className="login-field">
                    <p>Mot de passe</p>
                    <input  type="password" ref={this.password}/> 
                    <div className="password-visibility-btn"> <label htmlFor="see-psw" >Voir mot de passe</label> <input onChange={(e)=>{this.voirMdp(e)}} id="see-psw" type="checkbox"/> </div>
                </div>

                <input className="submit" type="submit" value="Soumettre"/>

            </form>
        
        }
    }

    

    getHeader(){
        const { isAuthentificated } = this.state;
        
        if(isAuthentificated === true){
            return  <div ref={this.headerRef} className="header display-none">
                <p className={this.state.selectedPage === "Bienvenue" ? 'active-menu-option' : null} onClick={()=>{this.setState({selectedPage:"Bienvenue"})}} >Bienvenue</p>
                <p className={this.state.selectedPage === "Formations" ? 'active-menu-option' : null} onClick={()=>{this.setState({selectedPage:"Formations"})}}>Formations</p>
                <p className={this.state.selectedPage === "Expérience" ? 'active-menu-option' : null} onClick={()=>{this.setState({selectedPage:"Expérience"})}}>Expérience</p>
                <p className={this.state.selectedPage === "Services" ? 'active-menu-option' : null} onClick={()=>{this.setState({selectedPage:"Services"})}}>Services</p>
                <p className={this.state.selectedPage === "Coordonnées" ? 'active-menu-option' : null} onClick={()=>{this.setState({selectedPage:"Coordonnées"})}}>Coordonnées</p>
            </div>
        }
    }

    handleMenu = e => {

        const menu = this.headerRef.current;
        menu.classList.toggle("display-none")
    }


    render() {
        return (
            <div className="admin">

                <section className="admin-log-option">
                    {
                        this.state.isAuthentificated !== true ? null :
                        <div onClick={(e)=>{this.logOut(e)}}>Se déconnecter</div>
                    }
                    <div onClick={()=>{this.props.history.push('/accueil')}}>Aller au site principal</div>
                </section>

                {
                    this.state.isAuthentificated !== true ? null :
                    <div className="menu-icon" onClick={(e)=>{this.handleMenu(e)}}><img alt="menu-icon" width="25px" src="./images/menu-icon.png"/><span>{this.state.selectedPage}</span></div>
                }
                
               {this.getHeader()}
               {this.getAdmin()}
            </div>
        )
    }
}

export default Admin