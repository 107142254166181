import React, { Component } from 'react'
import Citation from '../utils/Citation';
import Body from '../utils/Body';

export class Experience extends Component {

    constructor(){
        super();
        this.state = {
            selectedPage: null
        }
    }

    setSelectedPage(selectedPage){
        this.setState({
            selectedPage
        })
    }

    render() {
        const activeStyle = {
            textDecoration: 'underline'
        }
        return (
            <div className="admin-bienvenue">
                <div className="header-options header-services">
                <p onClick={()=>{this.setSelectedPage("Motifs de consultation")}} style={this.state.selectedPage === "Motifs de consultation" ? activeStyle : null}>Motifs de consultations</p>
                <p onClick={()=>{this.setSelectedPage("Honoraires")}} style={this.state.selectedPage === "Honoraires" ? activeStyle : null}>Honoraires</p>
                <p onClick={()=>{this.setSelectedPage("citation")}} style={this.state.selectedPage === "citation" ? activeStyle : null}>Citation</p>
                </div>
                <div className="admin-intro" style={this.state.selectedPage === null ? {display: "block" } : {display: "none"}}>
                    <h2>Site administrateur</h2>
                    <p>Commencez à modifier le site principal en accédant à l'une des options du sous-menu</p>
                </div>

                <div style={this.state.selectedPage === 'Motifs de consultation' ? {display: "block" } : {display: "none"}}><Body canBeDeleted={true} title="Motifs de consultations" route="https://francegenest.herokuapp.com/services/consultations"/></div>
                <div style={this.state.selectedPage === 'Honoraires' ? {display: "block" } : {display: "none"}}><Body canBeDeleted={true} title="Honoraires" route="https://francegenest.herokuapp.com/services/honoraires"/></div>
                <div style={this.state.selectedPage === 'citation' ? {display: "block" } : {display: "none"}}><Citation title="Citation" route="https://francegenest.herokuapp.com/services/citation" /></div>
            </div>
        )
    }
}

export default Experience
