import React, { Component } from 'react';
import axios from 'axios';

export class Footer extends Component {

    constructor(props){
        super();
        this.state = {
            telephones: null,
        }
    }

    componentDidMount(){
        this.getPage()
    }

    getPage() {
        let telephones;

        const getTelephones = axios.get('https://francegenest.herokuapp.com/coordonnees/telephone')
        .then( (res) => {
            telephones = res.data;
        })

        Promise.all([getTelephones]).then( () => {
            this.setState({
                telephones
            })
        })

    }

    manageRouting(path){
        this.props.history.push(path); 
        window.scrollTo(0,0) 
    }

    render() {
        return (
            <div className="Footer">

                <div className="footer-logo-container">
                    <img src='./images/logo.png' width="100px" height="100px" alt='logo'/>
                    <p>France Genest</p>
                    <p>Psychologue</p>  
                </div>

                {
                    this.props.location.pathname === "/admin" ? 
                    <div onClick={()=>{ window.scrollTo({top: 0, behavior: 'smooth'}); }} > <img src='./images/top-arrow.png' width="20px" height="10px" alt='top arrow'/> </div>
                    :
                    <section>
                        

                        <div className="footer-menu-container">
                            <div className="footer-menu">
                                <p onClick={()=>{this.manageRouting('/accueil')}} >Bienvenue</p>
                                <p onClick={()=>{this.manageRouting('/formations')}}>Formations et approches</p>
                                <p onClick={()=>{this.manageRouting('/experience')}}>Mon expérience</p>
                                <p onClick={()=>{this.manageRouting('/services')}}>Services</p>
                                <p onClick={()=>{this.manageRouting('/coordonnees')}}>Mes coordonnées</p>
                                <div  className="footer-telephone-container">
                                    <img alt="phone-icon" src="./images/phone-icon.png" width="15px" height="15px"/>
                                    {
                                        this.state.telephones != null ?
                                        <div dangerouslySetInnerHTML={{ __html: this.state.telephones[0].text_formatted }}></div> : null
                                    }
                                </div>
                                
                            </div>

                            <div onClick={()=>{ window.scrollTo({top: 0, behavior: 'smooth'}); }} > <img src='./images/top-arrow.png' width="20px" height="10px" alt='top arrow'/> </div>
                        </div>


                    </section>
                }
                
               <h5>Tous droits réservés &#169;2020 France Genest, Psychologue</h5>
            </div>
        )
    }
}

export default Footer